<template>
  <b-overlay variant="transparent">
    <b-card>
      <h3>{{ t('settings.file-storage.title') }}</h3>
      <b-row class="mt-2">
        <b-col>
          <div class="position-relative mb-1">
            <b-progress
              height="15px"
              :max="100"
              :variant="value / (max / 100) < 30 ? 'success' :
                  (value / (max / 100) < 70 ? 'warning' : 'danger')"
              :class="`progress-bar-${value / (max / 100) < 30 ? 'success' :
                  (value / (max / 100) < 70 ? 'warning' : 'danger')}`"
            >
              <b-progress-bar
                :value="(value / (max / 100)).toFixed(2)"
                :variant="value / (max / 100) < 30 ? 'success' :
                (value / (max / 100) < 70 ? 'warning' : 'danger')"
              />
            </b-progress>
            <b
              class="percents"
              :class="{white: value / (max / 100) > 50}"
            >
              {{ (value / (max / 100)).toFixed(2) }}%
            </b>
          </div>
          <b>
            {{ 1 > value
              ? `${(value * 1024).toFixed(2)} КБ`
              : value > 1024
              ? `${value / 1024} ГБ`
              : `${value} МБ` }} / {{
              max / 1024 > 1024
                  ? `${Math.ceil(max / 1024 / 1024)} ТБ`
                  : max > 1024
                      ? `${Math.ceil(max / 1024)} ГБ`
                      : `${max} МБ`
              }}
          </b>
          <b-alert
            v-if="value / (max / 100) > 70"
            show
            variant="danger"
            class="p-2 mt-2"
          >
            Файловое хранилище заполнено. Смените тариф для увеличения доступного места.
          </b-alert>
        </b-col>
      </b-row>
  </b-card>
  <b-card>
    <h3>{{ t('settings.file-storage.tariffs') }}</h3>
    <b-row class="mt-2">
      <b-col>
        <b-card-group deck>
          <b-card
            v-for="tariff in tariffs"
            :key="tariff.id"
            :title="tariff.title"
            :border-variant="userAccount.file_tariff.id === tariff.id ? 'primary' : ''"
            class="tariff-card"
          >
            <b-card-text>
                {{ tariff.description }}
            </b-card-text>
            <h4>
                {{
                    tariff.max_size / 1024 > 1024
                    ? `${Math.ceil(tariff.max_size / 1024 / 1024)} ТБ`
                    : tariff.max_size > 1024
                    ? `${Math.ceil(tariff.max_size / 1024)} ГБ`
                    : `${tariff.max_size} МБ`
                }}
            </h4>
            <template #footer>
              <div>
                <h3 class="mb-1">
                  {{ tariff.price > 0
                    ? `${new Intl.NumberFormat('ru-RU').format(tariff.price)} ₽`
                    : 'Бесплатно' }}
                </h3>
                <b-button
                  class="full-width"
                  variant="primary"
                  :disabled="userAccount.file_tariff.id === tariff.id || tariff.max_size < value"
                  @click="handleChangeTariff(tariff.id)"
                >
                  {{ t('settings.file-storage.button') }}
                </b-button>
              </div>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BCardText,
    BButton,
    BOverlay,
    BProgress,
    BProgressBar,
    BAlert
} from 'bootstrap-vue';
import {useStore} from "@/hooks/useStore";
import {computed, onMounted, ref} from "@vue/composition-api";
import {useToast} from "@/hooks/useToast";
import {useI18n} from "@/hooks/useI18n";
import {useRouter} from "@/hooks/useRouter";
import fileSizeConvert from "@/utils/fileSizeConvert";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardGroup,
        BCardText,
        BButton,
        BOverlay,
        BProgress,
        BProgressBar,
        BAlert
    },
    setup() {
        const store = useStore();
        const { successToast, dangerToast } = useToast();
        const { t } = useI18n();
        const router = useRouter();

        const userAccount = computed(() => store.getters['user/account']);
        const userRole = computed(() => store.getters['user/role'])
        const tariffs = ref([]);

        const max = userAccount.value.file_tariff.max_size;
        const value = userAccount.value.storage_size;

        const handleChangeTariff = (id) => {
            store.dispatch('user/changeTariff', id).then((res) => {
                if (res.status === 200) successToast(t('settings.tariffChange.title'), t('settings.tariffChange.text'));
                else dangerToast(t('settings.updateFiledMessage.title'), t('settings.updateFiledMessage.text'));
            });
        }

        onMounted(async () => {
            if (userRole.value.name !== 'admin') await router.redirect('/')
            else {
                const { data } = await store.dispatch('user/getTariffs');
                tariffs.value = data;
            }
        })

        return {
            t,
            userAccount,
            max,
            value,
            tariffs,
            handleChangeTariff,
            fileSizeConvert
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/utils.scss";
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.percents {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1;

  &.white {
    color: white;
  }
}

.tariff-card {
  box-shadow: $box-shadow !important
}

@include dark-theme {
  .tariff-card {
    box-shadow: $theme-dark-box-shadow !important;
    background: $theme-dark-body-bg !important;
  }
}
</style>
